<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Kebab <nobr>(<v-icon small class="mx-1">fas fa-ellipsis-v</v-icon>)</nobr> menu</h3>

	<p><img alt="Kebab Menu image" srcset="/docimages/kebab_menu-1.png 3x" class="k-help-img float-right">When viewing a framework, click the <v-icon small class="mx-1">fas fa-ellipsis-v</v-icon> “kebab” icon (so named because it resembles a shish kebab) to open a menu with framework options. The exact options you’ll see are context-dependent, and vary depending on your <span v-html="link('roles_framework', 'rights')"></span> for the framework, whether you are viewing on a computer or a smartphone, and other factors. But some of the options you may see include:</p>
	<ul>
		<li>Toggle between <span v-html="link('treeview', 'Tree view')"></span>, <span v-html="link('tileview', 'Tile view')"></span>, and <span v-html="link('tableitemsview', 'Table view')"></span></li>
		<li>Change the font size for items in the framework</li>
		<!-- <li>Show or hide item identifiers (<span v-html="link('identifiers', 'GUIDs')"></span>)</li>
		<li>Toggle whether or not to color-code items by item types</li>
		<li>Toggle <span v-html="link('add_comments', '“comment mode”')"></span> on or off</li>
		<li>Toggle whether or not to show <span v-html="link('view_assoc', 'associations')"></span> in item tiles</li> -->
		<li>Manage framework <span v-html="link('archives', 'archives')"></span><div style="font-size:0.9em; line-height:1.4em; text-indent:0px;"><b>Note:</b> you will only see this option if you are currently editing the framework.</div></li>
		<li>Copy to your clipboard a link to the current framework in <span v-html="app_name"/></li>
		<li>Copy to your clipboard a link to the current framework’s <span v-html="link('case_rest_api', 'CASE REST API')"></span></li>
		<li>Download this framework’s CASE JSON file</li>
		<li>View an access report for the framework<div style="font-size:0.9em; line-height:1.4em; text-indent:0px;"><b>Note:</b> you will only see this button if you are signed in to <span v-html="app_name"/> and have <span v-html="link('roles_framework', 'admin rights')"></span> to the framework.</div></li>
		<li>Manage <span v-html="link('user_roles_admin', 'user rights')"></span> for the framework<div style="font-size:0.9em; line-height:1.4em; text-indent:0px;"><b>Note:</b> you will only see this button if you are signed in to <span v-html="app_name"/> and have <span v-html="link('roles_framework', 'admin rights')"></span> to the framework.</div></li>
		<li>Enable/disable framework <span v-html="link('editing', 'editing')"></span></li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	